<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            type="year"
            label="검진년도"
            defaultStart="0y"
            defaultEnd="0y"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            label="근로자 부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            type="user"
            label="근로자"
            name="userId"
            v-model="searchParam.userId" />
        </div>
      </template>
    </c-search-box>
    <c-table
      title="뇌심혈관계 종합조사 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="뇌심혈관계 결과 엑셀 업로드" icon="note_add" @btnClicked="openExcelUploader" />
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" /> 
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'brainCardiovascular',
  data() {
    return {
      searchParam: {
        period: [],
        userId: '',
        heaBrainCardiovascularStepCd: null,
        deptCd: '',
      },
      grid: {
        columns: [
          {
            name: 'deptUserName',
            field: 'deptUserName',
            label: '근로자',
            align: 'center',
            sortable: false,
            style: 'width:200px',
            type: 'link'
          },
          {
            name: 'sexName',
            field: 'sexName',
            label: '성별',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'age',
            field: 'age',
            label: '나이(검진시점)',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'surveyDate',
            field: 'surveyDate',
            label: '검진일',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
        ],
        data: [],
      },
      evalItems: [],
      editable: true,
      listUrl: '',
      evalItemListUrl: '',
      excelUploadUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.bcs.survey.list.url
      this.evalItemListUrl = selectConfig.hea.bcs.evalItem.list.url
      this.excelUploadUrl = transactionConfig.hea.bcs.survey.excelupload.url
      // code setting
      this.getEvalItems();
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getEvalItems() {
      this.$http.url = this.evalItemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y'
      };
      this.$http.request((_result) => {
        this.evalItems = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      this.openPop(row)
    },
    openPop(row) {
      this.popupOptions.title = '뇌심혈관계';
      this.popupOptions.param = {
        heaBrainCardiovascularSurveyId: row ? row.heaBrainCardiovascularSurveyId : '',
      };
      this.popupOptions.target = () => import(`${'./brainCardiovascularDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    openExcelUploader() {
      this.popupOptions.title = '뇌심혈관계 결과 엑셀 업로드';
      this.popupOptions.target = () => import(`${'./brainCardiovascularExcelUpload.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploaderPopup;
    },
    closeExcelUploaderPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        let filterItem = ['empNo', 'surveyDate', 'error_message', 'userId', 'userName', 'deptCd', 'deptName', 'heaBrainCardiovascularSurveyId']
        this.$_.forEach(s_data, item => {
          let results = [];
          for (let key in item) {
            if (filterItem.indexOf(key) > -1) continue;
            let evalItem = this.$_.find(this.evalItems, { heaBrainCardiovascularEvalItemId: key });
            results.push({
              heaBrainCardiovascularEvalResultId: '',  // 뇌심혈관계 평가결과 일련번호
              heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
              heaBrainCardiovascularEvalItemId: key,  // 뇌심혈관계 평가항목 일련번호
              workSuitEvalItemClassCd: evalItem.workSuitEvalItemClassCd,  // 위험요인 구분코드
              riskFactors: evalItem.riskFactors,  // 위험요인
              riskFactorsResult: item[key],  // 위험요인 평가결과(현재상태)
              riskFactorsGoalResult: '',  // 목표상태
              healthConditionCd: '',  // 건강신호등
              regUserId: this.$store.getters.user.userId,  //
              editFlag: 'C'
            })
          }
          item.results = results;
          item.regUserId = this.$store.getters.user.userId;
          item.editFlag = 'C';
        })
        console.log(s_data)
        this.$http.url = this.excelUploadUrl;
        this.$http.type = 'PUT';
        this.$http.param = s_data
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
  }
};
</script>
